/**
 * Pega parâmetros da URL
 * @param  {String} url
 * @return {Object}
 */
export default function getURLParams(url) {
	let params = {};
	const parser = document.createElement("a");
	parser.href = url;
	const query = parser.search.substring(1);
	const vars = query.split("&");
	for (let i = 0; i < vars.length; i++) {
		let pair = vars[i].split("=");
		params[pair[0]] = decodeURIComponent(pair[1]);
	}
	return params;
}
