import Cookie from "js-cookie";

export const CookieTypes = {
	REQUIRED: "required",
	ANALYTIC: "analytic",
	PUBLICITY: "publicity",
	FUNCTIONAL: "functional"
};

const hasPermission = (type, cookieType) => {
	const typeLower = type.toLowerCase();
	const cookieTypeLower = cookieType?.toLowerCase();
	return (
		!cookieTypeLower || cookieTypeLower.includes(typeLower) || typeLower === CookieTypes.REQUIRED
	);
};

export const setCookie = (name, value, type, options = { path: '/'}) => {
	const cookies = Cookie.get();
	if (!hasPermission(type, cookies?.cookieUsage)) return false;
	Cookie.set(name, value, options);
	return true;
};

export const getCookie = (name, type) => {
	const cookies = Cookie.get();
	if (!hasPermission(type, cookies?.cookieUsage)) return undefined;

	return cookies[name];
};

export const removeCookie = (name, options = { path: '/'}) => {
	Cookie.remove(name, options);
};
