import { HAS_CLOSE_TOP_BAR, COOKIE_TOP_BAR_EXPIRATION_DAYS } from "../shared/constants";
import { getCookie, setCookie, CookieTypes } from "../shared/cookie";

export default class TopBar {
	constructor() {
		const body = document.getElementsByTagName("body")[0];
		const topBar = document.getElementById("top_bar");

		if (topBar !== null) {
			window.closeBar = () => {
				setCookie(HAS_CLOSE_TOP_BAR, true, CookieTypes.FUNCTIONAL, {
					expires: COOKIE_TOP_BAR_EXPIRATION_DAYS
				});
				topBar.classList.add("hide");
				body.classList.remove("has-top-bar", "top-bar-visible");
			};

			(window.verifyTopBar = () => {
				const hasCloseTopBar = getCookie(HAS_CLOSE_TOP_BAR, CookieTypes.FUNCTIONAL);
				hasCloseTopBar === undefined || hasCloseTopBar === false
					? topBar.classList.remove("hide")
					: "";
			})();
		}
	}
}
