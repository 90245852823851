import { createChatToken } from "./token";
import { getToken } from "../login/utils";
import * as Sentry from "@sentry/browser";

/**
 * Autentica o usuário logado no Chat do Zendesk.
 */
export const webwidgetAuth = () => {
    // Checa se o usuário está autenticado
    const token = getToken();
    if (token) {
        // Configuração do Zendesk
        window.zESettings = {
            webWidget: {
                authenticate: {
                    chat: {
                        // Chama a criação de um token quando o Chat carregar
                        jwtFn: function (callback) {
                            callback(createChatToken(token));
                        }
                    }
                }
            }
        };
    }
};

/**
 * Faz logout no WebWidget do Zendesk.
 */
export const webwidgetLogout = () => {
    try {
        if (typeof zE === "function") {
            zE(function () {
                zE('webWidget', 'logout');
            });
        }
    } catch (err) {
        Sentry.captureException(err);
    }
};
