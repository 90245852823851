/** API prefixes **/
export const SEARCH_FLIGHTS_API = process.env.SEARCH_FLIGHTS_API;
export const SEARCH_METASEARCH_API = process.env.SEARCH_METASEARCH_API;
export const CHECKOUT_API = process.env.CHECKOUT_BASE_URL;
export const USER_API = process.env.USER_BASE_URL;
export const LEGACY_API = process.env.LEGACY_API || "https://www-hmg.maxmilhas.com.br";
export const PRICES_API = process.env.PRICE_BASE_URL;
export const NEARBY_PLACES_API = process.env.NEARBY_PLACES_API;
export const MKT_PARTNERS_API = process.env.MKT_PARTNERS_API;
export const PRICE_ALERT_API = process.env.PRICE_ALERT_API;
export const SENDITTO_API = process.env.SENDITTO_BASE_URL;
export const OFFERS_API = process.env.OFFERS_API;
export const MULTI_ACCOUNTS_API = process.env.MULTI_ACCOUNTS_API;
export const BFF_API = process.env.BFF_API;
export const BFF_MALL_API = process.env.BFF_MALL_API;
export const IS_PROD = process.env.NODE_ENV === "production";
export const IS_HMG = process.env.NODE_ENV === "staging";
export const IS_DEV = process.env.NODE_ENV === "development";
export const IS_TEST = process.env.NODE_ENV === "test";
export const S3_URL = "https://s3.amazonaws.com/maxmilhas/website/";

export const AIRPORTS_SEARCH_URL = process.env.AIRPORTS_SEARCH_URL;

// Link data limite de cancelamento por coronavirus por companhia
export const CORONAVIRUS_DEADLINE_URL = process.env.CORONAVIRUS_DEADLINE_URL;

// Link icomoon for icons
export const ICOMOON_URL = process.env.ICOMOON_URL;

/** Application Config **/

export const SEARCH_FLIGHTS_PREFIX = "/busca-passagens-aereas/";
/** Front-end routes **/
export const ROUTE_CHECKOUT = `${SEARCH_FLIGHTS_PREFIX}checkout`;
export const ROUTE_PAYMENT = `${SEARCH_FLIGHTS_PREFIX}pagamento`;
export const ROUTE_SELECT_OFFERS = `${SEARCH_FLIGHTS_PREFIX}selecao-de-milhas`;
export const ROUTE_PASSENGERS = `${SEARCH_FLIGHTS_PREFIX}passageiros`;
export const ROUTE_LOGIN = `/login`;
export const ROUTE_AFTER_PAYMENT = `${SEARCH_FLIGHTS_PREFIX}pagamento-finalizado`;
export const ROUTE_REGISTER = `/cadastro`;
export const ROUTE_REMIND_PASSWORD = `/relembrar-senha`;

/** Flight constants **/
export const TRIPTYPE_ONEWAY = "OW";
export const TRIPTYPE_ROUNDTRIP = "RT";

export const STRETCH_INBOUND = "inbound";
export const STRETCH_OUTBOUND = "outbound";
/** States **/

/** Flight Billing statuses */
export const AIRLINE_CHEAPER_SCENARIO = "AIRLINE_CHEAPER_SCENARIO";
export const MAXMILHAS_CHEAPER_SCENARIO = "MAXMILHAS_CHEAPER_SCENARIO";
export const NO_MILES_ENOUGH_SCENARIO = "NO_MILES_ENOUGH_SCENARIO";

//Search Intention
export const GET_SEARCH_INTENTION = "GET_SEARCH_INTENTION";
export const GET_SEARCH_INTENTION_RECEIVED = "GET_SEARCH_INTENTION_RECEIVED";
export const GET_SEARCH_INTENTION_FROM_METASEARCH_RECEIVED =
	"GET_SEARCH_INTENTION_FROM_METASEARCH_RECEIVED";
export const GET_SEARCH_INTENTION_FAILED = "GET_SEARCH_INTENTION_FAILED";
export const GET_SEARCH_WITH_REFERENCE = "GET_SEARCH_WITH_REFERENCE";
export const UPDATE_SEARCH_PROGRESS = "UPDATE_SEARCH_PROGRESS";

//Get airports prefix
export const GET_AIRPORTS_PREFIX = "GET_AIRPORTS_PREFIX";

//Flights
export const FLIGHTS_RECEIVED = "FLIGHTS_RECEIVED";
export const SET_SEARCH_STATUS = "SET_SEARCH_STATUS";
export const UPDATE_FLIGHT_PRICES = "UPDATE_FLIGHT_PRICES";
export const UPDATING_FLIGHT_PRICES = "UPDATING_FLIGHT_PRICES";
export const UPDATING_PRICES = "UPDATING_PRICES";
export const FLIGHT_PRICES_UPDATED = "FLIGHT_PRICES_UPDATED";
export const TOGGLE_MILES_QUANTITY = "TOGGLE_MILES_QUANTITY";
export const SELECT_ROUNDTRIP_FLIGHTS = "SELECT_ROUNDTRIP_FLIGHTS";
export const UPDATE_FLIGHT = "UPDATE_FLIGHT";

export const GET_AIRLINE_FLIGHTS = "GET_AIRLINE_FLIGHTS";
export const AIRLINE_SEARCH_FLIGHTS_RECEIVED = "AIRLINE_SEARCH_FLIGHTS_RECEIVED";
export const AIRLINE_SEARCH_FLIGHTS_PENDING = "AIRLINE_SEARCH_FLIGHTS_PENDING";
export const AIRLINE_SEARCH_FLIGHTS_DONE = "AIRLINE_SEARCH_FLIGHTS_DONE";
export const AIRLINE_SEARCH_FLIGHTS_ERROR = "AIRLINE_SEARCH_FLIGHTS_ERROR";
export const AIRLINE_SEARCH_FLIGHTS_FETCHING = "AIRLINE_SEARCH_FLIGHTS_FETCHING";
export const UPDATE_SELECTED_FLIGHT = "UPDATE_SELECTED_FLIGHT";
export const UPDATE_SELECTED_FLIGHT_ERROR = "UPDATE_SELECTED_FLIGHT_ERROR";
export const UPDATE_FLIGHT_ERROR = "UPDATE_FLIGHT_ERROR";

//Session timer
export const SET_OFFER_TIMEOUT = "SET_OFFER_TIMEOUT";
export const DEFINE_OFFER_TIMEOUT = "DEFINE_OFFER_TIMEOUT";
export const RESET_OFFER_TIMEOUT = "RESET_OFFER_TIMEOUT";
export const CALL_TIMEOUT_ACTION = "CALL_TIMEOUT_ACTION";
export const SET_OFFER_MINUTES_INCREASE = 20;

//flights tab
export const CHANGE_TAB = "CHANGE_TAB";

//Flights Selected/unselected
export const SELECT_FLIGHT = "SELECT_FLIGHT";
export const UNSELECT_FLIGHT = "UNSELECT_FLIGHT";

//Flight Filters
export const SET_SEARCH_FILTER = "SET_SEARCH_FILTER";
export const CLEAN_AIRLINES_FILTER = "CLEAN_AIRLINES_FILTER";

//Flight order by
export const SET_ORDER_BY = "SET_ORDER_BY";
export const ORDER_ASC = "ORDER_ASC";
export const ORDER_DESC = "ORDER_DESC";

//Modal
export const MODAL_FIRST_DISPATCH = "MODAL_FIRST_DISPATCH";
export const TOGGLE_MODAL = "TOGGLE_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const OPEN_MODAL = "OPEN_MODAL";
export const SELECT_TYPE_FLIGHT = "SELECT_TYPE_FLIGHT";

//Checkout
export const CREATE_CHECKOUT = "CREATE_CHECKOUT";
export const CHECKOUT_CREATED = "CHECKOUT_CREATED";
export const CHECKOUT_CREATE_FAILED = "CHECKOUT_CREATE_FAILED";

//Offers
export const GET_OFFERS_LIST = "GET_OFFERS_LIST";
export const GET_OFFERS_LIST_SUCCESS = "GET_OFFERS_LIST_SUCCESS";
export const GET_OFFERS_LIST_FAILED = "GET_OFFERS_LIST_FAILED";
export const SELECT_OFFER_TO_BUY = "SELECT_OFFER_TO_BUY";
export const SET_OFFER_TO_BUY = "SET_OFFER_TO_BUY";
export const SET_OFFER_TO_BUY_SUCCESS = "SET_OFFER_TO_BUY_SUCCESS";
export const SET_OFFER_TO_BUY_FAILED = "SET_OFFER_TO_BUY_FAILED";
export const RESET_SELECTED_OFFER = "RESET_SELECTED_OFFER";
export const MULTIPLE_MILES_OFFER = 500;

//Auth - Login
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const CLEAN_LOGIN_ERRORS = "CLEAN_LOGIN_ERRORS";
export const SET_USER_CHECKOUT = "SET_USER_CHECKOUT";

//Account - Cancellation
export const GET_USER_ORDERS = "GET_USER_ORDERS";
export const SET_USER_ORDERS = "SET_USER_ORDERS";
export const SET_ORDER_CANCELLATION = "SET_ORDER_CANCELLATION";
export const SET_ORDERS_REBOOKING = "SET_ORDERS_REBOOKING";
export const SET_ORDER_DETAIL = "SET_ORDER_DETAIL";

//Account - SalesPassengers
export const GET_SALES_PASSENGERS = "GET_SALES_PASSENGERS";
export const GET_SALES_PASSENGERS_SUCCESS = "GET_SALES_PASSENGERS_SUCCESS";
export const GET_SALES_PASSENGERS_ERROR = "GET_SALES_PASSENGERS_ERROR";
export const GET_PROGRAMS = "GET_PROGRAMS";
export const GET_PROGRAMS_SUCCESS = "GET_PROGRAMS_SUCCESS";
export const GET_PROGRAMS_ERROR = "GET_PROGRAMS_ERROR";

export const COOKIE_NEWSLETTER_EXPIRATION_DAYS = 8;
export const COOKIE_TOP_BAR_EXPIRATION_DAYS = 2;
export const HAS_SUBSCRIBED_AT_NEWSLETTER = "HAS_SUBSCRIBED_AT_NEWSLETTER";
export const HAS_CLOSE_NEWSLETTER = "HAS_CLOSE_NEWSLETTER";
export const HAS_CLOSE_TOP_BAR = "HAS_CLOSE_TOP_BAR";

//Affiliates - Pixel
export const GET_PIXEL_AFFILIATES = "GET_PIXEL_AFFILIATES";
export const GET_PIXEL_AFFILIATES_SUCCESS = "GET_PIXEL_AFFILIATES_SUCCESS";
export const GET_PIXEL_AFFILIATES_ERROR = "GET_PIXEL_AFFILIATES_ERROR";

//Affiliates - Name
export const GET_NAME_AFFILIATES = "GET_NAME_AFFILIATES";
export const GET_NAME_AFFILIATES_SUCCESS = "GET_NAME_AFFILIATES_SUCCESS";
export const GET_NAME_AFFILIATES_ERROR = "GET_NAME_AFFILIATES_ERROR";

export const REGEX_VALIDATION_EMAIL = /([\w.-]+@([\w-]+)\.+\w{2,})/;

// Link de cancelamento cias para Latam e Gol
export const LATAM_CANCELLLATION_LINK =
	"https://www.latam.com/pt_br/planeje-e-compre/conheca-os-perfis-de-tarifas/";
export const GOL_CANCELLATION_LINK =
	"https://www.voegol.com.br/pt/informacoes/multa-credito-reembolso-de-passagem/";

// Promotion - Change Text
export const PROMOTION_TEXT_TITLE = "Semana do consumidor";
// export const PROMOTION_TEXT_DATE = "15 de outubro";
// export const PROMOTION_TEXT_DATE_PREFIX = "Em dias";

export const GOL_ACRONYM = "Gol";
export const GOL_FIDELITY_PROGRAM = 4;
export const GOL_TWO_FACTOR_MESSAGE = "Você poderá receber um contato da Maxmilhas através do whatsapp ou ligação no momento da venda das suas milhas. É essencial que você responda rapidamente a este contato para que possamos realizar a emissão da passagem na Gol.";

export const EMAIL_REDIRECT_POLL_TIMEOUT = 180;
export const Type2FA = {
	EMAIL_REDIRECT: "email_redirect",
	CHIPEIRA: "chipeira",
	WHATSAPP_CONTACT: "whatsapp_contact"
}
