import R from "ramda";
import moment from "moment";
import getURLParams from "./getURLParams";
import Cookie from "js-cookie";
import {
	ORDER_ASC,
	ORDER_DESC,
	AIRLINE_SEARCH_FLIGHTS_FETCHING,
	AIRLINE_SEARCH_FLIGHTS_PENDING,
	AIRLINE_SEARCH_FLIGHTS_DONE,
	AIRLINE_SEARCH_FLIGHTS_ERROR,
	SEARCH_FLIGHTS_PREFIX,
	S3_URL,
	ROUTE_LOGIN,
	NEARBY_PLACES_API,
	AIRPORTS_SEARCH_URL
} from "./constants";
import axios from "axios";
import "../shared/pubsub-triggers";
import { CookieTypes, getCookie, setCookie } from "./cookie";

const _passengersTypeArray = ["adult", "child", "infant"];
const _paymentMethods = ["credit_card", "transfer"];
const _airlines = ["gol", "latam", "avianca", "azul", "tap"];

const extractAttributesFromObject = (obj, attributes) => {
	return Object.keys(obj).reduce((outputObj, attribute) => {
		if (attributes.indexOf(attribute) > -1) outputObj[attribute] = obj[attribute];

		return outputObj;
	}, {});
};


/**
 * Captura items do blog que nao seja de determinada categoria
 */
export const getItemsOnBlog = (items) => {
	if (items.length) {
		const arrayOf = (nodelist) => {
			let result = [];

			for (const item of nodelist) {
				result.push(item);
			}
			
			return result;
		}

		const hasCategory = (item) => {
			const categories = item.querySelectorAll('category');
			const excludeCategory = ['Passagens aéreas'];
			let returnValue = true;
			
			for (const category of categories) {
				if (excludeCategory.includes(category.textContent)) {
					returnValue = false
				}
			}

			return returnValue;
		}

		const filtered = arrayOf(items).filter(hasCategory);

		return filtered;
	}	
}

/**
 * Randomiza arrays
 */
export const shuffleArray = originalArray => {
	const array = [].concat(originalArray);
	let currentIndex = array.length,
		temporaryValue,
		randomIndex;

	// While there remain elements to shuffle...
	while (0 !== currentIndex) {
		// Pick a remaining element...
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex -= 1;

		// And swap it with the current element.
		temporaryValue = array[currentIndex];
		array[currentIndex] = array[randomIndex];
		array[randomIndex] = temporaryValue;
	}

	return array;
};

/**
 * Alternativa a extractValues (indisponível no safari)
 */
export const extractValues = obj => {
	var concat = [];
	for (var i in obj) {
		if (obj.hasOwnProperty(i)) {
			concat.push(obj[i]);
		}
	}
	return concat;
};

export const getLuggageFrom = flight => {
	if (flight.otaAvailableIn !== "airline") {
		const maxPricing = bestMaxMilhasPrice(flight.pricing);

		return isValid(maxPricing) &&
			isValid(maxPricing.luggage) &&
			isValid(maxPricing.luggage.carryOn) &&
			isValid(maxPricing.luggage.checked)
			? maxPricing.luggage
			: null;
	}

	return null;
};

/**
 * Retorna se o valor passado como argumento é válido (não é null nem undefined)
 *
 * @param {*} value
 * @returns {Boolean}
 */
export const isValid = value => value !== null && value !== undefined;

/**
 * Verifica se str faz match com a URL de busca
 * (busca-passagens-aereas/RT/ABC/DEF/YYYY-MM-DD/YYYY-MM-DD/1/0/0/EC)
 * O regex em questão testa apenas se a contém dados do voo (ida ou volta)
 * e as Iatas da url
 * @param {String} str String da url a ser testada (a função foi criada
 * pensando-se em window.pathname)
 * @returns {Boolean}
 */
export const currentlyOnSearch = str => str.match(/(RT|OW)\/\w{3}\/\w{3}/) !== null;

/**
 * Retorna o objecto {flight} oposto ao trecho selecionado -
 * Comumente usado em {state.flights.selectedFlights}.
 * Caso {stretch} seja outbound, retorna o objeto flight em
 * {selectedFlightsCollection.inbound}
 *
 * @param {String} stretch
 * @param {Object} selectedFlightCollection
 * @returns {Object} Objeto do voo ou null caso stretch não tenha sido passado
 */
export const getOppositeFlight = (stretch, selectedFlightCollection) => {
	if (!stretch || !selectedFlightCollection) return null;

	if (
		selectedFlightCollection[getOppositeStretch(stretch)] &&
		selectedFlightCollection[getOppositeStretch(stretch)].length === 0
	) {
		return null;
	}

	return selectedFlightCollection[getOppositeStretch(stretch)];
};

/**
 * Retorna trecho oposto ao selecionado
 * ex: stretch = 'inbound' -> retorna 'outbound'
 *
 * @param {String} stretch
 * @returns String inbound ou outbound
 */

export const getOppositeStretch = stretch => {
	if (stretch === "inbound") return "outbound";
	if (stretch === "outbound") return "inbound";

	return null;
};
/**
 * Informa a soma de voos inbound e outbound de uma collectiom
 * @param {Object} flightsCollection
 * @returns {Number} Quantidade de voos em inbound e outbound
 */
export const flightsSum = flightsCollection => {
	return extractValues(flightsCollection).reduce(
		(flightsCollectionSum, flightsCollection) => (flightsCollectionSum += flightsCollection.length),
		0
	);
};

/**
 * Faz merge de voos
 * @param {Object} originFlights
 * @param {Object} newFlights
 * @returns {Array}
 */
export const mergeFlights = (originFlights, newFlights, airline) => {
	if (
		newFlights.length > 0 &&
		newFlights[0].otaAvailableIn === "maxmilhas" &&
		newFlights[0].airlineTarget === "other"
	) {
		newFlights = newFlights.filter(flight => flight.airline === airline);
	}
	const airlineToSearch = newFlights.length > 0 ? newFlights[0].airline : airline;
	const filteredAir = originFlights.filter(originalF => originalF.airline !== airlineToSearch);

	return filteredAir.concat(newFlights);
};

/**
 * Retorna o preço mais barato entre airline e milhas
 * @param {Number} airlinePrice
 * @param {Number} milesPrice
 * @returns Number
 */
export const getLowestPrice = (airlinePrice, milesPrice) => {
	if (!airlinePrice || airlinePrice > milesPrice) return milesPrice;

	if (airlinePrice < milesPrice) return airlinePrice;
};

export const stretchTranslation = {
	outbound: "ida",
	inbound: "volta"
};

export const stretchLegacyTranslation = {
	outbound: "go",
	inbound: "return"
};

export const cabinTranslations = {
	EC: "Econômica",
	EX: "Executiva"
};

export const feesTranslations = {
	BOARDING_TAX: "Taxa de embarque",
	CONVENIENCE_FEE: "Taxa de conveniência",
	SERVICE_FEE: "Taxa de serviço",
	LUGGAGE_FEE: "Bagagem",
	AIRLINE_SURCHARGE: "Sobretaxas e outros encargos",
	boardingFee: "Taxa de embarque",
	convenienceFee: "Taxa de conveniência",
	serviceFee: "Taxa de serviço",
	luggageFee: "Bagagem"
};

export const fromToTranslation = {
	outbound: "",
	inbound: ""
};

export const classNameTranslation = {
	[AIRLINE_SEARCH_FLIGHTS_FETCHING]: "loader-fetching",
	[AIRLINE_SEARCH_FLIGHTS_PENDING]: "loader-pending",
	[AIRLINE_SEARCH_FLIGHTS_DONE]: "loader-done",
	[AIRLINE_SEARCH_FLIGHTS_ERROR]: "loader-done"
};

export const passengerTypeTranslation = {
	adult: "Adultos",
	child: "Crianças",
	infant: "Bebês"
};

/**
 * Retorna o voo de uma coleção a partir do seu id (flights.id)
 * @param {Array} flightsCollection
 * @param {String} flightId
 */
export const getFlightById = (flightsCollection, flightId) => {
	return flightsCollection.filter(flight => flight.id === flightId)[0];
};

/**
 * Retorna true se a precificação mais barata
 * dos voos (combinados) forem diferentes
 * ex: ida mais barato na airline, volta mais barato em milhas => true
 * Podia ser feito uma simples comparação entre duas stretches
 * mas esse código visa implementações futuras de multi-trechos.
 * * trechos únicos retornam false
 * * trechos com precificação igual retornam false
 * * não preenchimento do argumento retorna false
 * @param {Array} stretches array com trechos
 * @returns {Boolean} um booleano relativo ás condições acima
 */
export const combinedFlightsHasDifferentCheaperPricings = stretches => {
	let combinedPricings = [];

	if (!stretches || stretches.length == 1) {
		return false;
	}

	stretches.forEach(stretch => {
		if (stretch) {
			combinedPricings.push(
				calculateCheapestPrice(
					stretch.pricing.airline == null ? null : stretch.pricing.airline.adult,
					stretch.pricing.miles == null ? null : stretch.pricing.miles.adult,
					!isValid(stretch.pricing.ota) ? null : stretch.pricing.ota.adult
				)
			);
		}
	});

	if (!combinedPricings.every((val, i, arr) => val === arr[0])) {
		return true;
	}

	return false;
};

/**
 * Verifica se em um array objetos de voos um deles não está disponível em milhas
 * @param {Array} stretches Array de objetos contendo dados de voos
 * @returns {Boolean} true ou false, a depender dos voos
 */

export const oneIsNotAvailableInMiles = stretches => {
	if (!stretches || stretches.length == 1) {
		return false;
	}

	const notAvailableStretches = stretches.filter(stretch => {
		if (stretch.otaAvailableIn !== "maxmilhas" && stretch.otaAvailableIn !== "both") {
			return stretch;
		}
	});

	return notAvailableStretches.length > 0;
};

/**
 * Verifica se um array objetos de voos possui companhias iguais
 *  * Poderia ter sido feito uma simples comparação entre duas stretches
 * mas esse código visa implementações futuras de multi-trechos.
 *  * A função retorna true caso haja apenas um item no array de objetos
 *   do argumento
 * @param {Array} stretches Array de objetos contendo dados de voos
 * @returns {Boolean} true ou false, a depender dos voos
 */
export const stretchesHasSameCompanies = stretches => {
	if (!stretches || stretches.length == 1) {
		return false;
	}

	const stretchesHasSameCompanies = stretches.reduce((currentAirline, stretch) => {
		if (!stretch) {
			return false;
		}

		return currentAirline == stretch.airline ? stretch.airline : false;
	}, stretches[0].airline);

	return stretchesHasSameCompanies !== false;
};

/**
 * Função auxiliadora, usada para filtrar array de objetos
 * usando seu atributo
 * @param {String} attribute
 */
export const by = attribute => (a, b) => {
	if (a[attribute] < b[attribute]) return -1;
	if (a[attribute] > b[attribute]) return 1;
	return 0;
};

/**
 * Retorna um objeto contnedo o voo mais barato de uma coleção de voos
 * @param {Array} cheaperFlightsArray array de voos baratos de diferentes companhias aereas
 */
export const getCheaperFlight = cheaperFlightsArray => {
	if (!cheaperFlightsArray) return;

	if (cheaperFlightsArray.constructor !== Array) return;

	let airlineCheaper = cheaperFlightsArray.sort(by("airline"))[0];
	let milesCheaper = cheaperFlightsArray.sort(by("miles"))[0];

	if (!airlineCheaper && !milesCheaper) return;

	return airlineCheaper.airline < milesCheaper.miles ? airlineCheaper : milesCheaper;
};

/**
 * Retorna um array com os parâmetros de busca, de acordo com o que foi escrito na url
 * @param {String} searchPrefix
 */
export const getUrlFlightSearchParams = (
	searchPrefix = SEARCH_FLIGHTS_PREFIX,
	flightParamsDefault = window.location.href
) => {
	if (!searchPrefix) return {};

	let flightParams;

	try {
		flightParams = flightParamsDefault
			.replace("#checkout", "")
			.split(searchPrefix)[1]
			.split("/");
	} catch (e) {
		return;
	}

	const mountedParams = {
		tripType: flightParams[0],
		from: flightParams[1],
		to: flightParams[2],
		outboundDate: flightParams[3]
	};

	if (flightParams.length == 9)
		return {
			...mountedParams,
			inboundDate: flightParams[4],
			adults: parseInt(flightParams[5]),
			children: parseInt(flightParams[6]),
			infants: parseInt(flightParams[7]),
			cabin: flightParams[8].substr(0, 2)
		};

	if (flightParams.length == 8)
		return {
			...mountedParams,
			adults: parseInt(flightParams[4]),
			children: parseInt(flightParams[5]),
			infants: parseInt(flightParams[6]),
			cabin: flightParams[7].substr(0, 2)
		};
};

/**
 * Preenche um numero com zeros ou um caractere a ser escolhido
 * @param {Number} n a ser convertido
 * @param {Number} width numero de zeros a serem adicionados
 * @param {String} z Caractere preenchedor (senao preenchido, será zero)
 */
export const pad = (n, width = 2, z) => {
	z = z || "0";
	n = n + "";
	return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

/**
 * Formata o label de paradas de acordo com o número passado em {stops}
 * @param {Number} stops
 */
export const stopsFormatter = stops => {
	if (stops == 0 || stops == "direct") return "Voo direto";
	if (stops == 1) return "1 parada";
	if (stops > 1) return `${stops} paradas`;
};

/**
 * Converte segundos em uma string no formato hhmm
 * @param {Number} seconds
 */
export const secondsToHHMM = seconds => {
	let minutes = Math.floor(seconds / 60);
	seconds = seconds % 60;
	let hours = Math.floor(minutes / 60);
	minutes = minutes % 60;
	return pad(hours) + ":" + pad(minutes);
};

/**
 * Extrai as horas de um datestring
 * @param {String} date
 */
export const dateToHHMM = date => {
	if (!date) return;

	return date.match(/[0-9]{2}\:[0-9]{2}/) !== null ? date.match(/[0-9]{2}\:[0-9]{2}/)[0] : "";
};

/**
 * retorna a diferença entre duas datas
 * @param {DateString} startDate data inicial (formato "yyyy-mm-ddThh:mm:ssZ")
 * @param {DateString} finishDate data final (formato "yyyy-mm-ddThh:mm:ssZ")
 * @param {String} quantifier medida de tempo usada para o calculo
 * @returns {String} diferença entre as datas
 */
export const dateDifference = (startDate, finishDate, quantifier = "") => {
	const start = moment(startDate);
	const end = moment(finishDate);
	return end.diff(start, quantifier);
};

/**
 * Diferença em anos para a data informada
 * @param {String} dateEntered
 */
export const differenceInYears = dateEntered => {
	const date = moment(dateEntered, "DD/MM/YYYY", true);
	const years = moment().diff(date, "years");
	return years;
};

/**
 * Devolve um objeto contendo dados para exibição do menor preço
 * @param {Number} airlinePrice
 * @param {Number} milesPrice
 * @param {Number} otaPrice
 * @returns {Object}
 */
export const decorateCheapestPrice = (_airlinePrice, _milesPrice, _otaPrice) => {
	const calculatedCheapestPrice = calculateCheapestPrice(_airlinePrice, _milesPrice, _otaPrice);

	let milesPrice;
	let airlinePrice;

	if (calculatedCheapestPrice === "ota") {
		return {
			airlineHasCheaperPrice: false,
			cheapestPrice: _otaPrice.fare,
			info: `Disponível MaxMilhas`
		};
	}

	if (calculatedCheapestPrice === "exclusive") {
		return {
			airlineHasCheaperPrice: false,
			cheapestPrice: _milesPrice.fare,
			info: `Exclusivo MaxMilhas`
		};
	}

	if (calculatedCheapestPrice === "exclusive_airline") {
		return {
			airlineHasCheaperPrice: true,
			cheapestPrice: _airlinePrice.fare,
			info: `Exclusivo Cia. Aérea`
		};
	}

	if (calculatedCheapestPrice === "maxmilhas") {
		milesPrice = saleTotalPerPassengerType(_milesPrice);
		airlinePrice = saleTotalPerPassengerType(_airlinePrice);

		const savingsPercent = parseInt((1 - milesPrice / airlinePrice) * 100);
		const infoMessage = savingsPercent >= 1 ? savingsPercent + "% " : "";

		return {
			airlineHasCheaperPrice: false,
			cheapestPrice: _milesPrice.fare,
			info: `Menor preço na MaxMilhas :)`,
			savings: infoMessage
		};
	}

	if (calculatedCheapestPrice === "airline") {
		airlinePrice = _airlinePrice.fare;

		return {
			airlineHasCheaperPrice: true,
			cheapestPrice: airlinePrice,
			info: `Menor preço na Cia. Aérea`
		};
	}
};
/**
 * Retorna uma string dizendo o tipo de voo mais barato
 * @param {Object} airlinePrice
 * @param {Object} milesPrice
 */
export const calculateCheapestPrice = (airlinePrice, milesPrice, otaPrice) => {
	try {
		const airlinePricePerAdult = airlinePrice == null ? null : airlinePrice.fare;
		const milesPricePerAdult = milesPrice == null ? null : milesPrice.fare;

		if (otaPrice !== null) {
			return "ota";
		}

		if (airlinePrice === null) {
			return "exclusive";
		}

		if (milesPrice === null) {
			return "exclusive_airline";
		}

		if (airlinePricePerAdult > milesPricePerAdult || airlinePricePerAdult == milesPricePerAdult) {
			return "maxmilhas";
		}

		if (airlinePricePerAdult < milesPricePerAdult) {
			return "airline";
		}
	} catch (e) {
		console.log("deu ruim com esses params ", airlinePrice, milesPrice);
		console.log("erro: ", e);
	}
};

export const setCheaperPriceData = flightData => {
	const pricing = flightData;
	const airlinePricing = pricing.airline === null ? null : pricing.airline.adult;
	const milesPricing = pricing.miles === null ? null : pricing.miles.adult;
	const otaPricing = !isValid(pricing.ota) ? null : pricing.ota.adult;

	const cheapestPriceData = decorateCheapestPrice(airlinePricing, milesPricing, otaPricing);
	return cheapestPriceData;
};

/**
 * Exibe um número entre parênteses. Caso o resultado seja
 * zero, exibe uma string vazia
 * @param {Numnrt} num número a ser decorado
 */
export const decorateWithParenthesis = num => `${num == 0 ? "" : "(" + num + ")"}`;

export const convertHoursToInt = hour =>
	parseInt(hour.match(/[0-9]{2}:[0-9]{2}/)[0].replace(":", ""));

export const departureRuleDawn = time => time >= 0 && time <= 559;

export const departureRuleMorning = time => time >= 600 && time <= 1159;

export const departureRuleAfternoon = time => time >= 1200 && time <= 1759;

export const departureRuleNight = time => time >= 1800 && time <= 2359;

export const filterLabels = {
	manha: { name: "Manhã" },
	tarde: { name: "Tarde" },
	noite: { name: "Noite" },
	madrugada: { name: "Madrugada" }
};

/**
 * Mostra a qual período do dia um voo pertence
 * @param {String} departureDate
 * @returns {String}
 */
export const getDepartureString = departureDate => {
	const time = convertHoursToInt(departureDate);

	if (departureRuleDawn(time)) return "madrugada";

	if (departureRuleMorning(time)) return "manha";

	if (departureRuleAfternoon(time)) return "tarde";

	if (departureRuleNight(time)) return "noite";
};

/**
 * Pega o objeto de filtros e reduz á quantidade de filtros
 * selecionados. O objeto contem 5 cláusulas e só deve retornar
 * As cláusulas que foram selecionadas.
 * @param {Object} filterObj Objeto contendo filtros de voos
 * @returns {Object}
 */
const getfilterClauses = filterObj => {
	return extractValues(filterObj).reduce(
		(clausesObj, clauseArr, i) =>
			clauseArr.length > 0 ? { ...clausesObj, [Object.keys(filterObj)[i]]: clauseArr } : clausesObj,
		{}
	);
};

/**
 * Retorna voos filtrados
 * @param {Array} flightsCollection voos a serem filtrados
 * @param {Object} flightsFilter filtros selecionados
 * @returns {Array} Objetos filtrados
 */
export const outputFilteredFlights = (flightsCollection, flightsFilter, orderClauses) => {
	const filterClauses = extractValues(getfilterClauses(flightsFilter)).length;
	let sort;

	//seta filtros
	if (filterClauses > 0)
		flightsCollection = flightsCollection.filter(flight => {
			let clausesFound = 0;

			if (flightsFilter.airlines && flightsFilter.airlines.indexOf(flight.airline) > -1)
				clausesFound++;

			if (
				flightsFilter.destinationAirports &&
				flightsFilter.destinationAirports.indexOf(flight.to) > -1
			)
				clausesFound++;

			if (flightsFilter.originAirports && flightsFilter.originAirports.indexOf(flight.from) > -1)
				clausesFound++;

			if (flightsFilter.stops && flightsFilter.stops.indexOf(flight.stops) > -1) clausesFound++;

			if (
				flightsFilter.departureTimes &&
				flightsFilter.departureTimes.indexOf(getDepartureString(flight.departureDate)) > -1
			)
				clausesFound++;

			const fee =
				flight.otaAvailableIn !== "airline" && flight.pricing.mmBestPriceAt === "miles"
					? "passagemMax"
					: flight.otaAvailableIn !== "airline" && flight.pricing.mmBestPriceAt === "ota"
						? "passagemConvencional"
						: "";

			if (flightsFilter.feeTypes && flightsFilter.feeTypes.indexOf(fee) > -1) clausesFound++;

			return clausesFound == filterClauses;
		});

	//seta ordenação
	if (orderClauses && orderClauses.order && orderClauses.column) {
		if (orderClauses.order == ORDER_ASC)
			sort = R.sortWith([R.ascend(R.prop(orderClauses.column)), R.ascend(R.prop("duration"))]);

		if (orderClauses.order == ORDER_DESC)
			sort = R.sortWith([R.descend(R.prop(orderClauses.column))]);
	}

	if (!sort) return flightsCollection;

	return sort(flightsCollection);
};

/**
 * Converte um número inteiro em um número formatado com pontos.
 * @param {Number} num o número a ser convertido
 * @returns {String} o número convertido (ex: 1000 retorna 1.000)
 */
export const addDecimalPoints = num => {
	var inputValue = num
		.toString()
		.replace(".", "")
		.split("")
		.reverse()
		.join("");
	var newValue = "";
	for (let i = 0; i < inputValue.length; i++) {
		if (i > 0 && i % 3 == 0) newValue += ".";

		newValue += inputValue[i];
	}
	return newValue
		.split("")
		.reverse()
		.join("");
};

/**
 * Função de um map que decora o objeto Flight com
 * o preço mais barato(entre airline e milhas), para ordenar a
 * lista por preço apropriadamente
 * @param {Object} flight
 */
export const setCheaperPrice = flight => {
	let priceWithTaxes, priceTotalWithTaxes = 0;

	if (flight.otaAvailableIn === "airline") {
		priceWithTaxes = saleTotalPerPassengerType(flight.pricing.airline.adult);
	} else {
		const maxPrice = bestMaxMilhasPrice(flight.pricing);
		priceWithTaxes = saleTotalPerPassengerType(maxPrice.adult);
		priceTotalWithTaxes = maxPrice.saleTotal;
	}

	flight.showPrice = priceWithTaxes;
	flight.showPriceWithTaxes = priceWithTaxes;
	flight.maxSaleTotal = priceTotalWithTaxes;

	return flight;
};

export const setStringToDirectFlight = flight => {
	if (flight.stops == 0) {
		flight.stops = "direct";
	}
	return flight;
};

export const decorateFlight = flight => {
	const compose = R.pipe(setCheaperPrice, setStringToDirectFlight);
	return compose(flight);
};

/**
 * Retorna a soma de todas as fees
 * @param {Array} fees objeto contendo atributos {fee} e {value}
 * @returns {Number} soma das fees
 */
export const feesSum = fees => fees.reduce((feeSum, fee) => (feeSum += fee.value), 0);

/**
 * Retorna um agrupamento de fees somadas por tipo de passageiro
 * @param {Object} pricingObj objeto com preços (miles, airline)
 * @returns {Object} contendo {adults:{fees de adulto}, children:{fees de children}, infants:{fees de infants}}
 */
export const getFeesSumPerPassengerType = pricingObj => {
	let fees = {};

	Object.keys(pricingObj).forEach(item => {
		if (_passengersTypeArray.indexOf(item) > -1) {
			pricingObj[item].fees.forEach(fee => {
				if (!fees[fee.type]) fees[fee.type] = 0;

				fees[fee.type] += fee.value * pricingObj[item].quantity;
			});
		}
	});

	return fees;
};

/**
 * Soma grupos de atributos em um array de objetos
 * @see {getFeesSumPerPassengerType}
 * @param {Array} attributeGroup array de objetos contendo atributos a serem somados
 * @returns {Object} objeto com atributos somados
 */
export const sumAttributes = attributeGroup => {
	let feesTotal = {};

	attributeGroup.forEach(feeGroup => {
		Object.keys(feeGroup).forEach(feeType => {
			if (!feesTotal[feeType]) feesTotal[feeType] = 0;

			feesTotal[feeType] += feeGroup[feeType];
		});
	});

	return feesTotal;
};
/**
 * Retorna um objeto contendo a soma dos das taxas na ida e na volta
 * e as quantidades de passageiros, por tipo de passageiro,
 * eg: { adult: {quantity: 1, fare: 234}, infant: {quantity: 2, fare: 392}, children: {quantity: 0, fare: 233}}
 * (adult, children, infant)
 * @param {Array} flightPricesArray  Array de objetos contendo pricing
 * @returns {Object} objeto do tipo {quantity : x, fare: xxx}
 */
export const sumFlightPricesByPassengerType = flightPricesArray => {
	let total = {};

	flightPricesArray.forEach(priceGroup => {
		Object.keys(priceGroup).forEach(item => {
			if (!total[item]) total[item] = { fare: 0, quantity: 0 };

			total[item] = {
				quantity: priceGroup[item].quantity,
				fare: priceGroup[item].fare + total[item].fare
			};
		});
	});

	return total;
};

/**
 * Aplica máscara em números inteiros. ex: 3000000 -> 3.000.000
 * @param {Number} number Numero inteiro
 */
export const intFormat = number => {
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

/**
 * Gera um redirecionamento para um site (mm ou airlines)
 * levando em conta a combinacao de voos passados como parametro
 * O redirecionamento deve apontar para o site com o preço final
 * mais barato
 * @see {setCheaperPriceData}
 *
 * @param {Object} flightData Objeto contendo infos de um voo
 * @param {Object} selectedFlights Objeto {inbound: [flight], outbound: [flight]}
 * @param {String} searchID String contendo id de retorno da intencao
 * @param {Object} searchParams Objeto contendo parametros da busca
 * @param {Window} _window objeto window (passado por parametro para ser mockavel)
 */
export const searchAlternativeFlight = (
	flightData,
	selectedFlights,
	searchID,
	searchParams,
	_window,
	redirectToCompany = false
) => {
	if (!flightData || !searchID || !selectedFlights || !searchParams || !_window) {
		return;
	}

	const airlineHasCheaperPrice =
		flightData.otaAvailableIn === "airline" ||
		(flightData.otaAvailableIn === "both" && flightData.pricing.bestPriceAt === "airline");

	const extractAttrs = ["arrivalDate", "departureDate", "from", "number", "to"];
	const encodedFlights = outputBase64FromSelectedFlights(
		selectedFlights,
		extractAttrs,
		airlineHasCheaperPrice || redirectToCompany ? "airline" : undefined
	);

	let redirectURL = "";

	if (airlineHasCheaperPrice || redirectToCompany) {
		const mmUrl = `http://www.maxmilhas.com.br/redirecionando/${flightData.airline}`;
		const legacyStretchName = stretchLegacyTranslation[flightData.direction];
		redirectURL = `${mmUrl}?search=${searchID}&stretch=${legacyStretchName}&flights=${encodedFlights}`;
	} else {
		const origin = _window.location.origin;
		redirectURL = origin + setUrlFromFlightObject(flightData, searchParams);
	}

	const redirectTo = url => _window.open(url, "_blank");

	redirectTo(redirectURL);
};

/**
 * Gera um redirecionamento para o site da companhia aérea
 * levando em conta a combinacao de voos passados como parametro
 * O redirecionamento deve apontar para o site com o preço final
 * mais barato
 * @see {setCheaperPriceData}
 *
 * @param {Object} flightData Objeto contendo infos de um voo
 * @param {Object} selectedFlights Objeto {inbound: [flight], outbound: [flight]}
 * @param {String} searchID String contendo id de retorno da intencao
 * @param {Object} searchParams Objeto contendo parametros da busca
 * @param {Window} _window objeto window (passado por parametro para ser mockavel)
 */
export const redirectToAirline = (
	flightData,
	selectedFlights,
	searchID,
	searchParams,
	_window,
	direction,
	redirectToCompany = false
) => {
	if (!flightData || !searchID || !selectedFlights || !searchParams || !_window) {
		return;
	}

	const { airline } = selectedFlights[direction][0];

	if (airline === "gol") {
		return searchAlternativeFlight(
			flightData,
			selectedFlights,
			searchID,
			searchParams,
			_window,
			redirectToCompany
		);
	}

	const airlineHasCheaperPrice =
		flightData.otaAvailableIn === "airline" ||
		(flightData.otaAvailableIn === "both" && flightData.pricing.bestPriceAt === "airline");

	const typeSearch =
		selectedFlights.inbound &&
		selectedFlights.inbound[0].airline === selectedFlights.outbound[0].airline &&
		(selectedFlights.inbound[0].otaAvailableIn === "airline" ||
			(selectedFlights.inbound[0].otaAvailableIn === "both" &&
				selectedFlights.inbound[0].pricing.bestPriceAt === "airline"))
			? "RoundTrip"
			: "OneWay";

	const { adults, children, infants } = searchParams,
		{ from, to, cabin } = flightData;

	const returnDate = searchParams.inboundDate ? searchParams.inboundDate : false;

	const outboundDate =
		direction === "inbound" ? searchParams.inboundDate : searchParams.outboundDate;

	let redirectURL = "";

	if (airlineHasCheaperPrice || redirectToCompany) {
		redirectURL = generateAirlineRedirectLink(
			airline,
			outboundDate,
			from,
			to,
			adults,
			children,
			infants,
			typeSearch,
			returnDate,
			cabin
		);
	} else {
		const origin = _window.location.origin;
		redirectURL = origin + setUrlFromFlightObject(flightData, searchParams);
	}

	const redirectTo = url => _window.open(url, "_blank");

	redirectTo(redirectURL);
};

const generateAirlineRedirectLink = (
	airline,
	departureDate,
	from,
	to,
	adults,
	children = 0,
	infants = 0,
	typeSearch,
	returnDate,
	cabin
) => {
	let fDeparturedate,
		fReturnDate,
		ftypeSearch,
		fcabin,
		departureDay,
		departureAnomes,
		returnDay,
		returnAnomes;

	switch (airline) {
		case "azul":
			return ` http://ad.zanox.com/ppc/?32448556C327820782T&ULP=[[&departure=${from}&arrival=${to}&numPax=${adults}&numPaxCHD=${children}&numPaxINF=${infants}&typeSearch=${typeSearch}
			&departureDate=${departureDate}&${returnDate ? `returnDate=${returnDate}` : ""}]]`;

		case "avianca":
			(fDeparturedate = departureDate.split("-").join("")),
				(fReturnDate = returnDate ? returnDate.split("-").join("") : false),
				(ftypeSearch = typeSearch === "RoundTrip" ? "RT" : "OW"),
				(fcabin = cabin === "EC" ? "Economy" : "Executive");

			return `https://www.avianca.com.br/busca?ORG=${from}&DEST=${to}&OUTBOUND_DATE=${fDeparturedate}
			${
				fReturnDate ? `&INBOUND_DATE=${fReturnDate}` : ""
			}&QT_ADT=${adults}&QT_CHD=${children}&QT_INF=${infants}&CABIN=${fcabin}&LANG=BR&TRIPTYPE=${ftypeSearch}`;

		case "latam":
			ftypeSearch = typeSearch === "RoundTrip" ? "ida_vuelta" : "ida";
			departureDay = departureDate.slice(-2);
			departureAnomes = departureDate.slice(0, -3);
			returnDay = returnDate ? returnDate.slice(-2) : false;
			returnAnomes = returnDate ? returnDate.slice(0, -3) : false;

			return `https://www.latam.com/pt_br/apps/personas/booking?fecha1_dia=${departureDay}&fecha1_anomes=${departureAnomes}&from_city1=${from}&to_city1=${to}&ida_vuelta=${ftypeSearch}&nadults=${adults}&nchildren=${children}&ninfants=${infants}&cabina=Y${
				typeSearch === "RoundTrip"
					? `&fecha2_dia=${returnDay}&fecha2_anomes=${returnAnomes}&from_city2=${from}&to_city2=${to}`
					: ""
			}`;

		default:
			return "https://www.maxmilhas.com.br";
	}
};

/**
 * Retorna o valor da passagem por tipo de passageiro (adult, child, infant)
 * @param {Object} passengerObj objeto contendo fare e fees do passageiro
 * @returns {Number} soma de fare + soma das fees
 */
export const saleTotalPerPassengerType = passengerObj =>
	passengerObj.fare + feesSum(passengerObj.fees);

/**
 * Retorna o valor da passagem por tipo de passageiro (adult, child, infant)
 * @param {Object} passengerObj objeto contendo fare e fees do passageiro
 * @returns {Number} soma de fare + soma das fees
 */
export const saleTotalPerPassengerTypeWithoutTaxes = passengerObj => passengerObj.fare;

/**
 * Soma todas as taxas e as agrupa de acordo com seu nome (SERVICE_FEE, CONVENIENCE_FEE, etc)
 * @param {Array} flights contendo os voos da compra
 * @param {String} pricingType atributo contendo preço a ser comparado
 * @returns {Object} objeto contendo soma das taxas
 */
export const sumTaxesPerPurchase = flights => {
	let outboundTaxes,
		inboundTaxes = {};
	const is = (flight, direction) => flight.direction === direction;

	let outboundFlight = flights.filter(flight => is(flight, "outbound"));
	let inboundFlight = flights.filter(flight => is(flight, "inbound"));

	if (outboundFlight && outboundFlight.length > 0) {
		const maxOutboundPricing = bestMaxMilhasPrice(outboundFlight[0].pricing);

		if (maxOutboundPricing) outboundTaxes = getFeesSumPerPassengerType(maxOutboundPricing);
	}

	if (inboundFlight && inboundFlight.length > 0) {
		const maxInboundPricing = bestMaxMilhasPrice(inboundFlight[0].pricing);

		if (maxInboundPricing) inboundTaxes = getFeesSumPerPassengerType(maxInboundPricing);
	}

	if (outboundTaxes && inboundTaxes) {
		return sumAttributes([outboundTaxes, inboundTaxes]);
	}

	return null;
};

/**
 * Converte os dados em uma string de busca de voo
 * @param {Object} flightdata
 * @param {Object} passengerData
 * @param {String} urlPrefix
 * @returns {String}
 */
export const setUrlFromFlightObject = (flightdata, searchParams) =>
	`${SEARCH_FLIGHTS_PREFIX}OW/` +
	`${flightdata.from}/` +
	`${flightdata.to}/` +
	`${flightdata.departureDate.substring(0, 10)}/` +
	`${searchParams.adults}/` +
	`${searchParams.children}/` +
	`${searchParams.infants}/` +
	`${searchParams.cabin}`;

/**
 * Formata número menor que um em uma porcentagem (0.0248 => 2.48)
 * @param {Number} number numero a ser convertido
 */
export const numberToPercent = number =>
	`${(number * 100).toString().replace(/(\d+(\.\d{1,2})?)\d*/, "$1")}%`;

/**
 * Função que retorna as datas Zulu da API em dd/mm/aaaa
 * @param {string} theApiZuluDate
 */

// EX: "2017-10-05T23:30:00Z"
export const getDateFormatted = theApiZuluDate => {
	const day = theApiZuluDate.substr(8, 2);
	const month = theApiZuluDate.substr(5, 2);
	const year = theApiZuluDate.substr(0, 4);

	return day + "/" + month + "/" + year;
};

export const getSalesResumePerPassengerType = milesPricingObj =>
	Object.keys(milesPricingObj)
		.filter(passengerType => _passengersTypeArray.indexOf(passengerType) > -1)
		.reduce(
			(passengerFareSum, passengerType) => ({
				...passengerFareSum,
				[passengerType]: {
					quantity: milesPricingObj[passengerType].quantity,
					fare: milesPricingObj[passengerType].fare
				}
			}),
			{}
		);

export const getSalesResumePerPassengerTypeCampaing = (promotionObject, milesPricingObj) =>
	Object.keys(milesPricingObj)
		.filter(passengerType => _passengersTypeArray.indexOf(passengerType) > -1)
		.reduce(
			(passengerFareSum, passengerType) => ({
				...passengerFareSum,
				[passengerType]: {
					quantity: milesPricingObj[passengerType].quantity,
					fare: promotionObject[passengerType]
				}
			}),
			{}
		);

/**
 * Converte data no formato YYYY-MM-DD ou DD/MM/YYYY para um
 * objeto Date
 * @param {String} strDate data no formato YYYY-MM-DD ou DD/MM/YYYY
 * @returns {Object} Data em javascript
 */
export const stringDateToObjectDate = strDate => {
	const brDatePattern = /[0-9]{2}\/[0-9]{2}\/[0-9]{4}/;

	if (strDate.match(brDatePattern) !== null)
		strDate = strDate
			.split("/")
			.reverse()
			.join("-");

	return new Date(`${strDate} 00:00:00`.replace(/-/g, "/"));
};

export const reverseOrderBy = order => (order === ORDER_ASC ? ORDER_DESC : ORDER_ASC);

/**
 * Seletor simples estilo jquery
 * @param {Object} selector
 */
export const $ = item => {
	const selector = document.querySelectorAll(item);

	if (selector !== null && selector.length > 1) {
		return extractValues(selector);
	}

	if (selector !== null && selector.length == 1) {
		return selector[0];
	}

	return null;
};

/**
 * Seletor simples estilo jquery, que retorna um array de objetos
 * @param {Object} selector
 */
export const $$ = selector => document.querySelectorAll(selector);

/**
 * Helper para escrever html dentro de prop do react
 * @param {String} markup
 */
export function createMarkup(markup) {
	return function() {
		return { __html: markup };
	};
}

/**
 * Converte um número 1234 em R$ 1.234
 * @param {Number} value valor a ser convertido
 * @param {String} [currency] símbolo da moeda
 * @returns {String}
 */
export const formatCurrency = (value, currency) => {
	let currencyParse = currency ? currency : "R$ ";
	let valueParse = parseFloat(value)
		.toFixed(2)
		.toString()
		.split(".");
	return currencyParse + addDecimalPoints(valueParse[0]) + "," + pad(valueParse[1]);
};

/**
 * Converte um número 1234 em R$ 1.234
 * @param {Number} value valor a ser convertido
 * @param {String} [currency] símbolo da moeda
 * @returns {String}
 */
export const convertToReal = (value) => {
	return new Intl.NumberFormat('pt-BR', {
			style: 'currency',
			currency: 'BRL',
		}).format(value)
		.replace('.', '#')
		.replace(',', '.')
		.replace('#', ',');
};

/**
 * Cria uma string base-64 contendo informações dos voos selecionados
 * @param {Object} selectedFlights objeto contendo voos selecionados
 * @param {Array} extractAttrs atributos a serem extraídos do objeto fligths
 * @param {String} pricing tipo de preço - pode se passar 'airline' ou 'undefined', no
 * último caso, deve usar o valor mais baixo (showprice)
 * @returns {String} base64 contendo dados de busca dos trechos na airline
 */
export const outputBase64FromSelectedFlights = (selectedFlights, extractAttrs, pricing) => {
	let flights = {};

	const setPricing = (priceType, flight) =>
		priceType === "airline" && flight.pricing.airline !== null
			? flight.pricing.airline.saleTotal
			: flight.showPrice;

	if (selectedFlights.outbound && selectedFlights.outbound.length > 0) {
		const totalCost = selectedFlights.outbound;
		flights.go = extractAttributesFromObject(selectedFlights.outbound[0], extractAttrs);
		flights.go.totalCost = setPricing(pricing, selectedFlights.outbound[0]);
		flights.go.number = selectedFlights.outbound[0].flightNumber;
	}

	if (selectedFlights.inbound && selectedFlights.inbound.length > 0) {
		flights.return = extractAttributesFromObject(selectedFlights.inbound[0], extractAttrs);
		flights.return.totalCost = setPricing(pricing, selectedFlights.inbound[0]);
		flights.return.number = selectedFlights.inbound[0].flightNumber;
	}

	// return flights;

	return btoa(JSON.stringify(flights));
};

/**
 * Checa se o valor é vazio/inválido
 * @param value a ser checado
 * @returns {boolean}
 */
export const checkEmptyValue = value => {
	return value === "" || value === null || typeof value === "undefined";
};

/**
 * Checa se o valor é vazio/inválido
 * @param {String} str
 * @returns {String}
 */
export const capitalizeOne = str => {
	return str
		.charAt(0)
		.toUpperCase()
		.concat(str.slice(1).toLowerCase());
};

/**
 * Função compartilhada que verifica se os inputs de mês e ano
 * (da validação de pagamento com cartão de crédito)estão setados
 * @param {Object} event
 */
export const validateCreditCardDates = event => {
	const touchedSelects = $(".credit-card-inputs select");
	if (touchedSelects !== null && touchedSelects.constructor === Array) {
		const selectedDate = `${document.getElementById("credit_card_year").value}-${
			document.getElementById("credit_card_month").value
		}`;
		const selectedDateFormatted = moment(selectedDate).format("YYYY-MM");
		const nowDate = moment(Date.now()).format("YYYY-MM");
		const cardsInputs = document.querySelector(".credit-card-inputs");

		if (touchedSelects.length == 2) {
			return selectedDateFormatted >= nowDate;
		}
	}
};

/**
 * retorna os dados necessários para acessar um checkout
 */
export const checkoutCredentials = (
	_sessionStorage = window.sessionStorage,
	_location = window.location,
	_localStorage = window.localStorage
) => {
	const checkoutId = _sessionStorage.getItem("activeCheckoutId")
		? _sessionStorage.getItem("activeCheckoutId")
		: getURLParams(_location.href).id;

	return {
		checkoutId: checkoutId,
		au: _localStorage.getItem("au")
	};
};

/** Armazena token JWT em localStorage **/
export const storeToken = response => localStorage.setItem("au", response.data.token);

/** Redireciona após o logout executando ações necessárias **/
export const redirectAfterLogout = _window => {
	_window.localStorage.removeItem("au");
	_window.sessionStorage.removeItem("activeCheckoutId");
	_window.location.pathname = ROUTE_LOGIN + _window.location.search;
};

/** Redireciona para o login **/
export const redirectToLogin = _window => {
	_window.location.pathname = ROUTE_LOGIN + _window.location.search;
};

/**
 * Armazena string da url de pesquisa por voos atual
 */

export const setStoredAddress = (_sessionStorage = window.sessionStorage) => {
	window.sessionStorage.setItem("storedAddress", window.location.pathname);
};
/**
 * Devolve endereço pra o qual o usuário deve ser redirecionado após timeout do fluxo de compra (última pesquisa)
 * @returns {String}
 */
export const getRedirectAddressAfterTimeout = (_sessionStorage = window.sessionStorage) =>
	_sessionStorage.getItem("storedAddress");

export const concatImageS3 = pathName => `${S3_URL}img/${pathName}`;

export const convertMaskedDate = value => {
	if (checkEmptyValue(value)) {
		return "";
	}
	return value
		.split("/")
		.reverse()
		.join("-");
};

export const convertDateToMasked = value => {
	if (checkEmptyValue(value)) {
		return "";
	}
	return value
		.split("-")
		.reverse()
		.join("/");
};

export const defineLocale = () => {
	moment.defineLocale("pt-br", {
		months: "janeiro_fevereiro_março_abril_maio_junho_julho_agosto_setembro_outubro_novembro_dezembro".split(
			"_"
		),
		monthsShort: "jan_fev_mar_abr_mai_jun_jul_ago_set_out_nov_dez".split("_"),
		weekdays: "domingo_segunda-feira_terça-feira_quarta-feira_quinta-feira_sexta-feira_sábado".split(
			"_"
		),
		weekdaysShort: "dom_seg_ter_qua_qui_sex_sáb".split("_"),
		weekdaysMin: "dom_2ª_3ª_4ª_5ª_6ª_sáb".split("_"),
		longDateFormat: {
			LT: "HH:mm",
			L: "DD/MM/YYYY",
			LL: "D [de] MMMM [de] YYYY",
			LLL: "D [de] MMMM [de] YYYY [às] LT",
			LLLL: "dddd, D [de] MMMM [de] YYYY [às] LT"
		},
		calendar: {
			sameDay: "[Hoje às] LT",
			nextDay: "[Amanhã às] LT",
			nextWeek: "dddd [às] LT",
			lastDay: "[Ontem às] LT",
			lastWeek: function() {
				return this.day() === 0 || this.day() === 6
					? "[Último] dddd [às] LT" // Saturday + Sunday
					: "[Última] dddd [às] LT"; // Monday - Friday
			},
			sameElse: "L"
		},
		relativeTime: {
			future: "em %s",
			past: "%s atrás",
			s: "segundos",
			m: "um minuto",
			mm: "%d minutos",
			h: "uma hora",
			hh: "%d horas",
			d: "um dia",
			dd: "%d dias",
			M: "um mês",
			MM: "%d meses",
			y: "um ano",
			yy: "%d anos"
		},
		ordinal: "%dº"
	});
};

export const validateEmail = email => {
	const regexEmmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return regexEmmail.test(email);
};

export const gaVersionFeature = () => {
	const script = document.currentScript;

	if (script) {
		setCookie("cs", script.src.includes("static.maxmilhas.com.br"), CookieTypes.FUNCTIONAL)
		window.isMMVariant = getCookie("cs", CookieTypes.FUNCTIONAL) === "false";
	}
};

export const bestMaxMilhasPrice = pricing => {
	return pricing[pricing.mmBestPriceAt];
};

export const isViajanet = pricing => {
	return pricing.ota && pricing.ota.checkout && pricing.ota.checkout.target === "viajanet";
};

export const sumServiceFees = pricing => {
	let total = 0;

	pricing.adult.fees.forEach(item => {
		if (item.type === "SERVICE_FEE") {
			total += item.value;
		}
	});

	if (pricing.child) {
		pricing.child.fees.forEach(item => {
			if (item.type === "SERVICE_FEE") {
				total += item.value;
			}
		});
	}

	if (pricing.infant) {
		pricing.infant.fees.forEach(item => {
			if (item.type === "SERVICE_FEE") {
				total += item.value;
			}
		});
	}

	return total;
};

/**
 * pega a informação se o voo é de ida e volta e manda para a dito as informacoes dos voos selecionados
 * @params {Object} directionFlight informação de IDA ou Volta
 * @returns {Object}
 */

export const sendSelectedFlightDito = (directionFlight, type) => {
	let luggageInfo;

	if (directionFlight.pricing.miles === null) {
		luggageInfo = "verificar_na_cia";
	} else if (
		directionFlight.pricing.miles &&
		directionFlight.pricing.miles.luggage &&
		directionFlight.pricing.miles.luggage.checked
	) {
		if (directionFlight.pricing.miles.luggage.checked === null) {
			luggageInfo = "nao_incluso";
		} else if (directionFlight.pricing.miles.luggage.checked.prices.length > 0) {
			if (directionFlight.pricing.miles.luggage.checked.prices[0] === 0) {
				luggageInfo = "incluso";
			} else {
				luggageInfo = "a_incluir";
			}
		} else {
			luggageInfo = "nao_incluso";
		}
	}

	const typeFlight =
		directionFlight.otaAvailableIn === "airline"
			? "airline"
			: directionFlight.pricing.mmBestPriceAt;

	console.log(typeFlight);

	const flightPeriod = getDepartureString(directionFlight.departureDate);

	const getFee = function(obj, feeType) {
		if (obj && obj.adult && obj.adult.fees) {
			const feeObj = obj.adult.fees.filter(fee => fee.type == feeType);

			if (feeObj[0]) {
				return feeObj[0].value;
			} else {
				return null;
			}
		}
		return null;
	};

	const savings =
		directionFlight.pricing.savingPercentage > 0
			? Math.round(directionFlight.pricing.savingPercentage)
			: 0;

	let data = {};
	data[`${type}_aeroporto_origem`] = directionFlight.from;
	data[`${type}_aeroporto_destino`] = directionFlight.to;
	data[`${type}_preco_sem_taxas`] = parseFloat(
		saleTotalPerPassengerTypeWithoutTaxes(directionFlight.pricing[typeFlight].adult).toFixed(2)
	);
	data[`${type}_taxa_de_servico`] = getFee(directionFlight.pricing[typeFlight], "SERVICE_FEE");
	data[`${type}_taxa_de_embarque`] = getFee(directionFlight.pricing[typeFlight], "BOARDING_TAX");
	data[`${type}_preco_com_taxas`] = parseFloat(
		saleTotalPerPassengerType(directionFlight.pricing[typeFlight].adult).toFixed(2)
	);
	data[`${type}_qtd_milhas`] = directionFlight.pricing.miles
		? directionFlight.pricing.miles.adult.miles
		: null;
	data[`${type}_cia_aerea`] = directionFlight.airline;
	data[`${type}_percentual_economia`] = savings > 0 ? `${savings}%` : null;
	data[`${type}_qtd_escala`] = directionFlight.trips.length - 1;
	data[`${type}_mais_barato`] =
		directionFlight.otaAvailableIn === "airline"
			? "airline"
			: directionFlight.pricing.bestPriceAt === "airline"
				? "airline"
				: directionFlight.pricing.mmBestPriceAt;
	data[`${type}_periodo_voo`] = flightPeriod;
	data[`${type}_bagagem`] = luggageInfo;

	return data;
};

export const sendPaymentDito = activeCheckout => {
	const outboundFlight = activeCheckout.outboundFlight;
	const inboundFlight = activeCheckout.inboundFlight;
	const fullPassengers =
		activeCheckout.search.adults + activeCheckout.search.children + activeCheckout.search.infants;

	const todayDay = moment(new Date()).format("YYYY-MM-DD");
	const daysUntilTravel = dateDifference(todayDay, activeCheckout.search.outboundDate, "days");
	const typePayment =
		activeCheckout.payment.paymentMethod == "credit_card" ? "cartao_de_credito" : "transferencia";

	const totalWithoutFees =
		activeCheckout.milesPricing.billingAmountWithoutLuggage - activeCheckout.fees.TOTAL;

	const parcels = typePayment === "credit_card" ? activeCheckout.payment.summary.parcels : null;
	const brand = typePayment === "credit_card" ? activeCheckout.payment.summary.brand : null;

	const generateDate = (directionFlight, type) => {
		if (!directionFlight) {
			return {};
		}

		const priceWithoutFees = directionFlight.salePrice - directionFlight.boardingFee;
		const flightPeriod = getDepartureString(directionFlight.departureDate);
		const durationFormatted = secondsToHHMM(directionFlight.duration * 60);

		let luggageInfo;

		if (!directionFlight.luggage.checked) {
			luggageInfo = "nao_incluso";
		} else if (directionFlight.luggage.checked.prices.length > 0) {
			if (directionFlight.luggage.checked.prices[0] === 0) {
				luggageInfo = "incluso";
			} else {
				luggageInfo = "a_incluir";
			}
		} else {
			luggageInfo = "nao_incluso";
		}

		let data = {};
		data[`${type}_aeroporto_origem`] = directionFlight.airportFrom;
		data[`${type}_aeroporto_destino`] = directionFlight.airportTo;
		data[`${type}_qtd_escala`] = directionFlight.stops;
		data[`${type}_duracao_total`] = durationFormatted;
		data[`${type}_preco_sem_taxas`] = priceWithoutFees;
		data[`${type}_taxa_de_embarque`] = directionFlight.boardingFee;
		data[`${type}_preco_com_taxas`] = directionFlight.salePrice;
		data[`${type}_qtd_milhas`] = directionFlight.miles;
		data[`${type}_cia_aerea`] = directionFlight.airline;
		data[`${type}_periodo_voo`] = flightPeriod;
		data[`${type}_bagagem`] = luggageInfo;

		return data;
	};

	const dataOutboundFlight = generateDate(outboundFlight, "ida");
	const dataInboundFlight = generateDate(inboundFlight, "volta");

	let data = {
		id_pedido: activeCheckout.orderId.id,
		cupom_desconto: activeCheckout.discounts.coupon ? activeCheckout.discounts.coupon.code : null,
		tipo_pagamento: typePayment,
		parcelas: parcels,
		cartao_de_credito: brand,
		qtd_passageiros: fullPassengers,
		dias_ate_viagem: daysUntilTravel,
		tipo_destino: activeCheckout.search.isInternational ? "Internacional" : "Nacional",
		pais_origem: activeCheckout.search.fromCountry,
		pais_destino: activeCheckout.search.toCountry,
		preco_com_taxas_total: activeCheckout.milesPricing.billingAmount,
		preco_sem_taxas_total: totalWithoutFees,
		taxa_de_embarque_total: activeCheckout.fees.BOARDING_TAX,
		taxa_de_servico_total: activeCheckout.fees.SERVICE_FEE,
		economia_total: activeCheckout.milesPricing.economy,
		qtd_milhas_total: activeCheckout.milesPricing.miles,
		...dataOutboundFlight,
		...dataInboundFlight
	};

	return data;
};

/*
* Converte camelCase em underscore_case.
* @param { String } str a ser convertida.
* @returns { String }
*/

export const decamelize = function(string, options) {
	options = options || {};
	const separator = options.separator || "_";
	const split = options.split || /(?=[A-Z])/;

	return string
		.split(split)
		.join(separator)
		.toLowerCase();
};

/**
 * Transform array em objeto agrupando seus elementos de acordo com o resultado da callback
 * @param {Array} arr array a ser agrupado.
 * @param {Function} callback operação a ser aplicada a cada iteração. O valor de retorno determina o grupo.
 * @returns {Object} Objeto com elementos agrupados por propriedades com arrays como valores.
 */

export const groupByOp = (arr, callback) => {
	return arr.reduce((obj, item) => {
		const prop = callback(item);
		obj[prop] = obj[prop] || [];
		obj[prop].push(item);
		return obj;
	}, {});
};

/**
 * Transform objeto em string de class name, usando as chaves do objeto como nomes e os
 * valores como condição para concatenar ou não o nome.
 * @param {Objeto} obj contendo nomes e condições.
 * @param {Objeto} options opções extras
 * @param {Objeto} options.prefix prefixo a acrescentar em todas as propriedades
 * @returns {String} String com class names.
 */

export const getClassNames = (obj, options = { prefix: "" }) =>
	Object.keys(obj)
		.filter(name => obj[name] || obj[name] === 0)
		.map(name => `${options.prefix}${name.replace(/\$/g, obj[name])}`)
		.join(" ");

export const getMainIata = iata => {
	switch (iata) {
		case "STO":
		case "ARN":
			return "BMA";

		case "TXL":
		case "BER":
			return "SXF";

		case "MXP":
		case "LIN":
		case "MIL":
			return "BGY";

		case "PLU":
		case "BHZ":
			return "CNF";

		case "AEP":
		case "BUE":
			return "EZE";

		case "SVO":
		case "MOW":
			return "DME";

		case "SWF":
		case "LGA":
		case "EWR":
		case "NYC":
			return "JFK";

		case "VCP":
		case "GRU":
		case "SAO":
			return "CGH";

		case "LGW":
		case "LHR":
		case "LON":
			return "LCY";

		case "ORD":
		case "CHI":
			return "MDW";

		case "HND":
		case "TYO":
			return "NRT";

		case "ORY":
		case "PAR":
			return "CDG";

		case "GIG":
		case "RIO":
			return "SDU";

		case "SHA":
			return "PVG";

		default:
			return iata;
	}
};

export const getNearbyLocation = () => {
	const promise = new Promise((resolve, reject) => {
		const getGeoLocationFromAPI = (latitude, longitude) => {
			let url = `${NEARBY_PLACES_API}/airports?max=1`;

			if (latitude !== undefined && longitude !== undefined) {
				url += `&lat=${latitude}&lon=${longitude}`;
			}

			axios
				.get(url)
				.then(response => {
					const airports = response.data;
					resolve(airports);
				})
				.catch(error => {
					reject(error);
				});
		};

		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				position => {
					getGeoLocationFromAPI(position.coords.latitude, position.coords.longitude);
				},
				error => {
					getGeoLocationFromAPI();
				}
			);
		} else {
			getGeoLocationFromAPI();
		}
	});

	return promise;
};

export const getFlightsList = flights => {
	let flightsList = [];
	flightsList.push(createFlightObject(flights.outbound, "outbound"));
	if (typeof flights.inbound !== "undefined" && flights.inbound !== null) {
		flightsList.push(createFlightObject(flights.inbound, "inbound"));
	}
	return flightsList;
};

const createFlightObject = (flight, stretch) => {
	let title = "Ida";
	if (stretch !== "outbound") {
		title = "Volta";
	}
	return {
		title: title,
		stretch: stretch,
		id: flight.id,
		airline: flight.airline,
		flightNumber: flight.flightNumber,
		from: flight.from,
		departureDate: flight.departureDate,
		to: flight.to,
		arrivalDate: flight.arrivalDate
	};
};

export const getPassengerList = passengers => {
	let passengersList = [];
	if (typeof passengers !== "undefined" && passengers !== null) {
		if (
			typeof passengers.adults !== "undefined" &&
			passengers.adults !== null &&
			passengers.adults.length > 0
		) {
			passengers.adults.forEach(adult => {
				passengersList.push(createPassengerObject(adult));
			});
		}
		if (
			typeof passengers.children !== "undefined" &&
			passengers.children !== null &&
			passengers.children.length > 0
		) {
			passengers.children.forEach(child => {
				passengersList.push(createPassengerObject(child));
			});
		}
		if (
			typeof passengers.infants !== "undefined" &&
			passengers.infants !== null &&
			passengers.infants.length > 0
		) {
			passengers.infants.forEach(infant => {
				passengersList.push(createPassengerObject(infant));
			});
		}
	}

	return passengersList;
};

export const defineFlightCabin = (trip, airline, isOta) => {
	if (!isOta && airline !== "gol") {
		if (trip.isInternational) {
			return "Informação não disponibilizada pela Cia aérea";
		} else {
			return "Econômica";
		}
	}

	return trip.cabin ? cabinTranslations[trip.cabin] : "Informação não disponibilizada pela Cia aérea";
};

const createPassengerObject = passenger => {
	let objReturn = {};
	objReturn = {
		idpassengers: passenger.idpassengers,
		name: passenger.name,
		isChecked: false
	};
	return objReturn;
};

export const uuidv4 = () => {
	return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
		(c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
	);
};

/**
 * @param {string} iata Ex: bhz
 * @returns {string} Ex: Belo Horizonte - Todos, (BHZ)
 */
export const getAirportDescriptionByIata = async(iata) => {
	const airportSearch = await axios.get(`${AIRPORTS_SEARCH_URL}/${iata}`);
	const airport = airportSearch.data.find((airport)=> airport.iata === iata);
	return `${airport.city} - ${airport.name}, (${airport.iata})`
};