import R from "ramda";
import * as jwt from "jwt-simple";
import * as Sentry from "@sentry/browser";

/**
 * Create and return a Zendesk customer token for SSO and Chat authentication.
 * @param {string} token
 * @returns {null|string}
 */
export const createChatToken = (token) => {
    try {
        const userData = jwt.decode(token, "", true, "HS256");

        // Check if userData contains need fields
        if (R.isEmpty(userData.id) || R.isEmpty(userData.name) || R.isEmpty(userData.email)) {
            throw new Error("Id, name and e-mail fields not found on token.");
        }

        // Generate basic JWT payload for Zendesk
        const time = (new Date().getTime() / 1000);
        const payload = {
            iat: parseInt(time, 10),
            exp: parseInt(time + (60 * 5), 10),
            name: userData.name,
            email: userData.email,
            external_id: userData.id,
        };

        // Encode token
        return jwt.encode(payload, process.env.ZENDESK_CHAT_AUTH_SHARED_KEY, "HS256");
    } catch (err) {
        Sentry.captureException(err);
        return null;
    }
};
