import { CookieTypes, getCookie, setCookie } from "../shared/cookie";
import getURLParams from "../shared/getURLParams";

export const checkEmptyValue = value => {
	return value === "" || value === null || typeof value === "undefined";
};

export const trimInput = node => {
	node.onchange = event => {
		node.value = event.target.value.trim();
	};
};

export const storeToken = token => {
	localStorage.setItem("au", token);
	setCookie("au", token, CookieTypes.REQUIRED);
};

export const getToken = () => getCookie("au", CookieTypes.REQUIRED) || localStorage.getItem("au");

export const $ = element => document.querySelector(element);

/**
 * retorna o id do checkout atual
 */
export const getCheckoutId = (
	_sessionStorage = window.sessionStorage,
	_location = window.location,
	_localStorage = window.localStorage
) => {
	return _sessionStorage.getItem("activeCheckoutId")
		? _sessionStorage.getItem("activeCheckoutId")
		: getURLParams(_location.href).id;
};

export const requestSuccess = code => {
	return code >= 200 && code < 300 ? true : false;
};

export const getLoginErrorContent = type => {
	if (type === "CredentialsLockedException") {
		return `
            <div class="alert--icon">
                <i class="icon-close" ></i>
            </div>
            <div class="alert--message">
                <p>
                    Usuário BLOQUEADO. &nbsp;
                    <a
                        onclick={() => (window.location.pathname = ROUTE_REMIND_PASSWORD)}
                        class="a-link"
                    >
                        Troque sua senha
                    </a>
                    e em seguida faça login novamente.
                </p>
            </div>
        `;
	} else if (type === "BadCredentialsException") {
		return `
            <div class="alert--icon">
                <i class="icon-close" ></i>
            </div>
            <div class="alert--message">
                <p>Usuário ou senha inválidos.</p>
            </div>
        `;
	} else if (type === "BlockedConditionException") {
		return `
            <div class="alert--icon">
                <i class="icon-close" ></i>
            </div>
            <div class="alert--message">
                <p>Usuário bloqueado por excesso de tentativas, clique em <a onMouseOver="this.style.color='#fb3b3b'" href="/relembrar-senha" style="color: #fb3b3b;"><b>Esqueceu sua senha?</b></a> para refazer a sua senha e recuperar o seu acesso.</p>
            </div>
        `;
	} else {
		return `
            <div class="alert--icon">
                <i class="icon-close"></i>
            </div>
            <div class="alert--message">
                <p>Erro ao fazer login. Tente novamente.</p>
            </div>
        `;
	}
};

export const displayErrors = err => {
	if (err && err !== "Facebook login error") {
		const { type } = err;
		const divError = $("#div-error");

		divError.style.display = "flex";
		divError.innerHTML = getLoginErrorContent(type);
	}
};
