import MobileDetect from "mobile-detect";
import Cookie from "js-cookie";
import TopBar from "./partials/topBar";
import StickyHeader from "./components/header/StickyHeader";
import SideMenuHooks from "./components/header/SideMenu";
import chatMenuLadesk from "./chat-menu";
import { smoothScroll } from "./shared/smoothScroll";
import getURLParams from "./shared/getURLParams";
import { gaVersionFeature } from "./shared/utils";
import moment from "moment";

// Dropdown footer
const menuFooter = document.querySelectorAll(".menu-footer ul");
let menuItem;

for (var i = 0; i < menuFooter.length; i++) {
	menuItem = menuFooter[i];
	menuItem.addEventListener("click", function() {
		this.classList.toggle("active");
	});
}

// Dropdowns do newFooter
const moreOptionsPlaaceholder = document.querySelector(".form-group__placeholder");
const moreOptionsDropdown = document.querySelector(".form-group__dropdown");
const moreOptionsPlaceholderIcon = document.querySelector(".form-group__icon");
const infoDropdown = document.querySelectorAll(".info-dropdown");
let infoItem;

if (moreOptionsPlaaceholder) {
	moreOptionsPlaaceholder.addEventListener("click", function() {
		moreOptionsDropdown.classList.toggle("is-open");
		moreOptionsPlaceholderIcon.classList.toggle("is-active");
	});

	window.addEventListener("click", e => {
		const el = e.target;

		if (moreOptionsPlaaceholder.contains(el) || moreOptionsPlaaceholder === el) return null;

		if (moreOptionsDropdown.classList.contains("is-open")) {
			moreOptionsDropdown.classList.remove("is-open");
			moreOptionsPlaceholderIcon.classList.remove("is-active");
		}
	});
}

for (var i = 0; i < infoDropdown.length; i++) {
	infoItem = infoDropdown[i];
	infoItem.addEventListener("click", function() {
		this.classList.toggle("is-open");
	});
}

// New Header
const newHeaderMenu = document.querySelector(".newheader-menu-button");
const newHeaderMenuClose = document.querySelector(".newheader-menu-box-button");
const newHeaderLogin = document.querySelector(".newheader-usermenu-button--logged");
const newHeaderLoginClose = document.querySelector(".newheader-loggedmenu-user-icon");
if (newHeaderMenu) {
	newHeaderMenu.addEventListener("click", function() {
		document.querySelector(".newheader-menu-inner").classList.toggle("active");
	});

	newHeaderMenuClose.addEventListener("click", function() {
		document.querySelector(".newheader-menu-inner").classList.remove("active");
	});
}

if (newHeaderLogin) {
	newHeaderLogin.addEventListener("click", function() {
		document.querySelector(".newheader-loggedmenu").classList.toggle("active");
	});

	newHeaderLoginClose.addEventListener("click", function() {
		document.querySelector(".newheader-loggedmenu").classList.remove("active");
	});
}

// New Footer
const footerLink = value => {
	if (value.length > 1) {
		window.location.href = value;
	}
};

const headerScrollListener = () => {
	try {
		const header = document.querySelector(".newheader");
		const doc = document.documentElement;
		const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
		const pathName = window.location.pathname;
		const pathsWithTransparentHeader = ["/", "/vender-milhas", "/campanha-extra-bonus"];
		const limitWidth = pathName == "/campanha-extra-bonus" ? 0 : 1024;
		const isHeaderTransparent = pathsWithTransparentHeader.includes(pathName);
		// TODO: IF pra tratar apenas a pagina de vender-milhas
		if (pathName == "/vender-milhas") {
			if (top < 10 && window.innerWidth >= 768) {
				header.classList.add("transparent");
			} else {
				header.classList.contains("transparent") && header.classList.remove("transparent");
			}
		} else if (window.innerWidth >= limitWidth && isHeaderTransparent) {
			if (top < 10) {
				header.classList.add("transparent");
			} else {
				header.classList.contains("transparent") && header.classList.remove("transparent");
			}
		} else {
			header.classList.contains("transparent") && header.classList.remove("transparent");
		}
	} catch (e) {}
};

const headerSpaceCalc = () => {
	try {
		const header = document.querySelector(".newheader");
		const body = document.querySelector("body");
		const pathName = window.location.pathname;
		const pathsWithTransparentHeader = ["/", "/vender-milhas", "/campanha-extra-bonus"];
		const limitWidth = pathName == "/campanha-extra-bonus" ? 0 : 1024;
		const isHeaderTransparent = pathsWithTransparentHeader.includes(pathName);

		if (!body.classList.contains("search-page")) {
			if (
				(pathName === "/vender-milhas" && window.innerWidth >= 768) ||
				(window.innerWidth >= limitWidth && isHeaderTransparent)
			) {
				body.setAttribute("style", "padding-top: 0px");
			} else {
				body.setAttribute("style", `padding-top: ${header.offsetHeight}px`);
			}
		}
	} catch (e) {}
};

// Active New Header
const newHeader = () => {
	document.querySelector("body").classList.add("headernew");
	document.addEventListener("scroll", headerScrollListener);
	window.addEventListener("resize", headerSpaceCalc);

	headerSpaceCalc();
	headerScrollListener();
};

// Active New Footer
const newFooter = () => {
	document.querySelector("body").classList.add("footernew");
};

// this functions handle the DOM as requered for zendesk AB test
const zendeskAbTest = () => {
	const header = document.querySelector(".newheader");
	const frequentlyQuestions = document.querySelectorAll(".frequently-questions");
	const contactUs = document.querySelectorAll(".contact-us");

	if (header && window.linksABZendesk === "variante1") {
		frequentlyQuestions.forEach(item => (item.textContent = "Central de ajuda"));
	} else if (header && window.linksABZendesk === "variante2") {
		frequentlyQuestions.forEach(item => (item.textContent = "Central de ajuda"));
		contactUs.forEach(item => item.remove());
	}
};

// this function is a workaround to show the old clean-header on login page, since we dont heave a new clean-header
const loginHeader = () => {
	const path = window.location.pathname;

	try {
		const header = document.getElementById("header-fixed");
		const pageWrapper = document.querySelector(".page-wrapper");

		if (path === "/login") {
			header.setAttribute("style", "display: block !important");
			pageWrapper.setAttribute("style", "padding-top: 60px");
		}
	} catch (e) {}
};

window.newHeader = newHeader;
window.newFooter = newFooter;
window.footerLink = footerLink;

try {
	gaVersionFeature();
} catch (e) {}

export const extractValues = obj => {
	var concat = [];
	for (var i in obj) {
		if (obj.hasOwnProperty(i)) {
			concat.push(obj[i]);
		}
	}
	return concat;
};

//TODO incluir arquivo só de polyfills
if (!Array.prototype.includes) {
	Object.defineProperty(Array.prototype, "includes", {
		enumerable: false,
		value: function(obj) {
			var newArr = this.filter(function(el) {
				return el == obj;
			});
			return newArr.length > 0;
		}
	});
}

// Parâmetros da url
const params = getURLParams(window.location.href);

// Pega o parametro de cupom e insere no local storage
if (params && params.code) {
	sessionStorage.setItem("coupon", params.code);
}

// Parâmetros para exibição de voos em milhas ou tarifado
if (params && (params.tr || params.ml)) {
	sessionStorage.setItem("p", params.tr ? "tr" : "ml");
} else if (params && (params._tr || params._ml)) {
	sessionStorage.setItem("p", params._tr ? "_tr" : "_ml");
} else {
	if (
		document.body.classList.length === 1 &&
		document.body.classList[0] === "home-page" &&
		sessionStorage.getItem("p")
	) {
		sessionStorage.removeItem("p");
	}
}

// Polyfill do IntersectionObserver
if (!("IntersectionObserver" in window)) {
	var script = document.createElement("script");
	script.src =
		"https://raw.githubusercontent.com/w3c/IntersectionObserver/master/polyfill/intersection-observer.js";
	document.getElementsByTagName("head")[0].appendChild(script);
}

// Gera folha de estilo a partir do nome
window.generateStylesheet = chunkName => {
	const head = document.getElementsByTagName("head")[0];
	const appCSS = document.getElementById("appCSS");
	let link = document.createElement("link");
	let filename = chunkName.match(".css") !== null ? chunkName : chunkName + ".css";
	const buildURL = window.assets_base_url.match("8181") !== null ? "/" : "/css/";

	link.id = chunkName;
	link.rel = "stylesheet";
	link.href = `${window.assets_base_url}${buildURL}${
		window.mm_revision && window.mm_revision[filename] ? window.mm_revision[filename] : filename
	}`;

	if (appCSS) {
		head.insertBefore(link, appCSS);
	} else {
		head.appendChild(link);
	}
};

// Pega nome e versão do Browser do Usuário
window.getBrowser = () => {
	let ua = navigator.userAgent,
		tem,
		M =
			ua.match(/(opera|chrome|safari|firefox|msie|trident|samsungbrowser(?=\/))\/?\s*(\d+)/i) || [];
	if (/trident/i.test(M[1])) {
		tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
		return { name: "IE", version: tem[1] || "" };
	}
	if (M[1] === "Chrome") {
		tem = ua.match(/\bOPR|Edge\/(\d+)/);
		if (tem != null) {
			return { name: "Opera", version: tem[1] };
		}
	}
	M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
	if ((tem = ua.match(/version\/(\d+)/i)) != null) {
		M.splice(0, 0, "WebView");
		M.splice(1, 1, tem[1]);
	}
	return {
		name: M[0],
		version: M[1]
	};
};

// Retorna se a versão do usuário é compatível com o site ou não
window.browserIsCompatible = () => {
	let name = getBrowser().name;
	let version = Number(getBrowser().version);

	if (
		(name === "IE" && version <= 9) ||
		(name === "Edge" && version <= 15) ||
		(name === "Safari" && version <= 9) ||
		(name === "Chrome" && version <= 61) ||
		(name === "Firefox" && version <= 55) ||
		(name === "SamsungBrowser" && version <= 5) ||
		(name === "WebView" && version <= 3)
	) {
		return false;
	} else {
		return true;
	}
};

window.isMobileDevice = () => {
	const parser = new MobileDetect(window.navigator.userAgent || navigator.userAgent);
	return parser.mobile() !== null;
};

window.isTabletDevice = () => {
	const parser = new MobileDetect(window.navigator.userAgent || navigator.userAgent);
	return parser.tablet() !== null;
};

window.md = new MobileDetect(window.navigator.userAgent || navigator.userAgent);
window.Cookie = Cookie;

const inputsAutoCompSwitch = document.querySelectorAll("input");
inputsAutoCompSwitch.forEach(input => {
	input.addEventListener("focus", () => {
		input.removeAttribute("autocomplete");
		input.setAttribute("autocomplete", "new-p");
	});
});

// Gera CSS base e altera footer no load do site
window.addEventListener("load", function() {
	generateStylesheet("base.css");

	const elms = document.querySelectorAll("footer .logo-placeholder");
	const elmsList = Array.prototype.slice.call(elms);

	elmsList.forEach(elm => {
		if (elm && elm.className) {
			elm.className = elm.className.replace("logo-placeholder", "");
		}

		if (elm && elm.getAttribute("data-placeholder")) {
			elm.removeAttribute("data-placeholder");
		}
	});

	if (document.getElementById("certificates")) {
		document.querySelector("#certificates").innerHTML = `
      <h5>Certificados</h5>
      <a href="javascript:void(0)">
          <div id="armored_website">
              <param id="aw_preload" value="true" />
          </div>
          <script defer type="text/javascript" src="//selo.siteblindado.com/aw.js"></script>
      </a>
      <a href="http://www.reclameaqui.com.br/indices/84978/maxmilhas/" target="_blank">
          <div class="logo-ra"></div>
      </a>
	`;
	}

	newHeader();
	newFooter();
	loginHeader();
	zendeskAbTest();
});

(function(window, document) {
	let targets = document.querySelectorAll(".anchor-animation"),
		target = null;
	for (target in targets) {
		if (typeof targets[target] === "object") {
			targets[target].addEventListener("click", smoothScroll);
		}
	}

	/* Script dropdowns header home = Tirar dúvidas / Login */
	window.closeDropdown = () => {
		const openedMenus = document.querySelectorAll(".dropdown-menu.open");
		if (openedMenus.length > 0) {
			extractValues(openedMenus).map(menu => {
				menu.classList.remove("open");
			});
		}
	};

	window.dropDownToggle = event => {
		const name = event.target.parentElement.getAttribute("data-toggle");
		window.closeDropdown();
		document.querySelectorAll(name)[0].classList.add("open");
		event.stopPropagation();
	};

	const body = document.getElementsByTagName("body")[0];

	body.addEventListener("click", event => {
		const foundItems =
			typeof event.parentNode === "undefined"
				? false
				: event.parentNode.classList.contains("dropdown-toggle");
		if (!foundItems) {
			window.closeDropdown();
		}
	});
	/* Fim script dropdowns header */

	const topbar = new TopBar();

	StickyHeader();
	SideMenuHooks();
	chatMenuLadesk();
	headerHotelHrefAB();
})(window, document);

//Troca de bg do buscador de acordo com data

const today = moment(new Date()).format("DD-MM-YYYY");
const day12 = moment(new Date("06-12-2019")).format("DD-MM-YYYY");
const day13 = false;
const todayIs12 = today === day12;
const todayIs13 = today === day13;
const isInOriginalSite = window.location.hostname === "www.maxmilhas.com.br";
const canChangeBG = (todayIs12 && isInOriginalSite) || (todayIs13 && isInOriginalSite);

if (canChangeBG) {
	const imageName = todayIs12
		? "bg-namorados-dia-12.png"
		: todayIs13
		? "bg-namorados-dia-13.png"
		: undefined;
	document.getElementById(
		"envelope"
	).style.backgroundImage = `url("https://maxmilhas.s3.amazonaws.com/campanha/dia-dos-namorados-2019/${imageName}")`;
	document.getElementById("envelope").style.backgroundPosition = "center center";
}
