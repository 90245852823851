import { webwidgetAuth } from "./zendesk/webwidget";

/* Scripts Zendesk WebWidget */
// Autentica o usuário no webwidget
webwidgetAuth();


/**
 *  @FIXME remover este código de Ladesk após a virada para o Zendesk.
 *  Script chat menu ladesk
 *  */
export default function chatMenu() {
	var menu = document.querySelectorAll("#chatMenu")[0];
	let chatButton, openChatElement, optionChatInMenu, closeMenuChat;

	setTimeout(() => {
		chatButton = document.querySelectorAll(".circleRollButton")[0];

		if (chatButton !== undefined) {
			openChatElement = chatButton.parentNode.parentNode;
			optionChatInMenu = menu.querySelectorAll("#button-chat-with-us")[0];
			closeMenuChat = menu.querySelector(".close-menu-chat");

			optionChatInMenu.addEventListener("click", event => {
				openChatElement.click();
			});

			//stop ladesk chat initialize and toggle menu
			chatButton.addEventListener("click", event => {
				event.stopPropagation();
				menu.className = menu.className.indexOf("hidden") > -1 ? "chat-menu" : "chat-menu hidden";
			});

			//stop ladesk chat and close menu on icon x
			closeMenuChat.addEventListener("click", event => {
				event.stopPropagation();
				menu.className = "chat-menu hidden";
			});

			//stop ladesk chat initialize
			menu.addEventListener("click", event => {
				event.stopPropagation();
			});

			chatButton.parentNode.appendChild(menu);
		}
	}, 3000);
}
