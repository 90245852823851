export default function SideMenuHooks() {
	const overlay = document.getElementById("overlay_viewport"),
		sideMenu = document.getElementById("sidemenu"),
		menuButton = document.getElementById("menu_button"),
		body = document.getElementsByTagName("body")[0],
		menuGroup = menuButton.parentNode,
		sell_miles = document.getElementById("sell_miles"),
		search_tickets_button = document.getElementById("search_tickets_button");

	function closeSidemenu() {
		overlay.classList.remove("active");
		sideMenu.classList.remove("active");
		menuGroup.classList.remove("active");

		sideMenu.setAttribute("aria-hidden", "true");

		overlay.removeEventListener("click", closeSidemenu);
		body.classList.remove("menu-opened");
	}

	if (sideMenu && overlay) {
		sideMenu.classList.add("listen");

		sideMenu.setAttribute("aria-hidden", "true");

		menuButton.addEventListener("click", function() {
			body.classList.add("menu-opened");

			if (overlay.classList.contains("active")) {
				closeSidemenu();
				return;
			}

			overlay.classList.add("active");
			sideMenu.classList.add("active");
			menuGroup.classList.add("active");

			sideMenu.setAttribute("aria-hidden", "false");

			overlay.addEventListener("click", closeSidemenu);
		});
	}

	if (sell_miles && search_tickets_button) {
		sell_miles.addEventListener("click", closeSidemenu);
		search_tickets_button.addEventListener("click", closeSidemenu);
	}
}
