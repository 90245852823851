
export default function stickyHeader () {

  const body = document.getElementsByTagName('body')[0];
  const topBar = document.getElementById('top_bar');

  function init () {
    topBarControl();

    window.addEventListener('scroll', function () {
      topBarControl();
    })
  }

  function topBarControl () {
    if (topBar !== null) {
      if (!topBar.classList.contains('hidden')) {
        topBar.height = topBar.offsetHeight;
        body.classList.add('has-top-bar');

        if (window.pageYOffset > topBar.height) {
          body.classList.remove('top-bar-visible');
        } else {
          body.classList.add('top-bar-visible');
        }
      } else {
        topBar.height = 0;
        body.classList.remove('has-top-bar');
      }
    }
  }

  init();
}
